import 'core-js';
import 'react-app-polyfill/stable';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import { BrowserTracing } from '@sentry/tracing';

import App from './App';
import store from './store';
import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga4';
import './index.scss';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://f1ec30b0c5854f359e3a47684bc4a419@fcasentry.azurefd.net/2',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.1,
  });
}
ReactGA.initialize('G-T8Y13N269C');

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
